// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import Isotope from 'isotope-layout';

var Flickity = require('flickity');
require('flickity-imagesloaded');

require('@fancyapps/fancybox');

const LazyLoad = require('lazyload');


document.addEventListener("DOMContentLoaded", function () {

    // Menu mobile
    var hamburger = document.getElementById('hamburger');
    var html = document.getElementById('html');
    var body = document.body;
    var header = document.getElementById('header');

    var closeDiv = document.createElement('div');
    closeDiv.classList.add('e-close-div');
    closeDiv.style.position = 'fixed';
    closeDiv.style.top = 0;
    closeDiv.style.left = 0;
    closeDiv.style.width = '100vw';
    closeDiv.style.height = '100vh';
    closeDiv.style.zIndex = 0;

    closeDiv.addEventListener('click', function() {
      hamburger.classList.remove('is-active');
      html.classList.remove('is-active-menu-mobile');
      header.removeChild(closeDiv);
    });

    hamburger.addEventListener('click', function() {
      if (!this.classList.contains('is-active')) {
        this.classList.add('is-active');
        html.classList.add('is-active-menu-mobile');
        header.appendChild(closeDiv);
      } else {
        this.classList.remove('is-active');
        html.classList.remove('is-active-menu-mobile');
        if (header.contains(closeDiv)) {
          header.removeChild(closeDiv);
        }
      }
    });



    window.addEventListener('scroll', function() {
      var headerHeight = header.offsetHeight;

      if (window.scrollY > headerHeight) {
        html.classList.add('is-scroll-active');
      } else {
        html.classList.remove('is-scroll-active');
      }
    });

});



document.addEventListener("DOMContentLoaded", function() {
    var grid = document.querySelector(".c-carousel__products.isotope");
    var iso;

    if (grid) {
        iso = new Isotope(grid, {
            itemSelector: ".item"
        });

        

        // Inicializar LazyLoad
        let images = document.querySelectorAll(".item-poster");
        new LazyLoad(images);
        images.forEach(img => {
            img.addEventListener('load', function() {
                if (iso) {
                  iso.layout();
                  this.classList.add('loaded');
                }
            });
        });



        // Obtén los enlaces de filtrado del menú de navegación
        var filterLinks = document.querySelectorAll(".c-menu-category--js a.item-filter");

        // Agrega un evento de clic a cada enlace de filtrado
        filterLinks.forEach(function(link) {
            link.addEventListener("click", function(event) {
                event.preventDefault();



                // Obtiene el valor del atributo "data-filter" del enlace
                var filterValue = link.getAttribute("data-filter");

                // Filtra los elementos de Isotope según la categoría seleccionada
                iso.arrange({ filter: filterValue });

                
                // Actualiza el aspecto ratio de las imágenes según el filtro aplicado
                // Si el elemento con ID "js-nav-products" NO existe, actualiza el aspecto ratio de las imágenes.
                if (!document.getElementById("js-nav-products")) {
                  updateAspectRatio(filterValue);
                }

                // Manejar la clase is-active en los elementos del menú
                // Eliminar la clase is-active de todos los elementos del menú
                $('.item-filter').removeClass('is-active');

                // Agregar la clase is-active al elemento del menú seleccionado
                $(this).addClass('is-active');

                // Después de un pequeño retraso, forzamos a Isotope a recalcular las posiciones
                setTimeout(function() {
                    iso.layout();
                }, 120); // Un retraso de 120ms. Puedes ajustar este valor si es necesario.

                window.location.hash = filterValue.substring(1); // Eliminamos el punto al principio del valor

            });
        });
    }


  
 function updateAspectRatio(filterValue) {
    if (filterValue === '*') { // Si el filtro es 'Todas'
      // Restablecer aspecto ratio y width original
      $('.item').each(function() {
        const originalRatio = $(this).find('figure').attr('data-original-ratio');
        const originalWidth = $(this).attr('data-original-width');
        if (originalRatio) {
          $(this).find('figure').attr('style', originalRatio);
        }
        if (originalWidth) {
          $(this).css('width', originalWidth);
        }
      });
    } else {
      // Cambiar aspecto ratio y width
      $(filterValue).each(function() {
        const newRatio = '--aspect-ratio:1/1;--tablet-aspect-ratio:1/1'; // Ejemplo de nuevo ratio
        const newWidth = '33.33%';
        $(this).find('figure').attr('data-original-ratio', $(this).find('figure').attr('style')); // Guardar ratio original
        $(this).attr('data-original-width', $(this).css('width')); // Guardar width original
        $(this).find('figure').attr('style', newRatio);
        $(this).css('width', newWidth);
      });
    }
  }

    // Captura el fragmento de la URL (sin el símbolo #)
    let hash = window.location.hash.substr(1);
    // Si hay un fragmento y corresponde a uno de los filtros...
    if (hash) {
        let targetFilter = document.querySelector(`.item-filter[data-filter=".${hash}"]`);
        if (targetFilter) {
            // Desactiva cualquier filtro previamente activo
            let activeFilters = document.querySelectorAll('.item-filter.is-active');
            activeFilters.forEach(filter => filter.classList.remove('is-active'));

            // Fuerza un clic en el filtro correspondiente al fragmento de URL
            targetFilter.click();
        }
    }
});



var c_time_line = document.getElementsByClassName('c-time-line__carousel')[0];
if (c_time_line){
let flkty = new Flickity('.c-time-line__carousel', {
    "pageDots": false,
    "imagesLoaded": true,
    "pauseAutoPlayOnHover": false,
    "cellAlign": "center",
    "contain": false,
    "wrapAround": false,
    "prevNextButtons": false,
    "freeScroll" : false,
    "adaptiveHeight": false,
    "autoPlay": 6000,
    "draggable": true
});

flkty.on( 'dragStart', function() {
    flkty.stopPlayer(); 
    flkty.playPlayer(); 
});
}



$('.c-form__input').click(function(){
    $(this).addClass('active');
    $(this).find('input').focus();
  });
  $('.c-form__input input , .c-form__input textarea').focus(function(){
    $(this).addClass('active');
  });



// Scripts
require('./modules/scripts');



	









